<template>
    <div>
        <b-card bg-variant="Success" class="text-center" v-if="submitted">
          <b-button
          variant="primary"
          :to="{ name: 'crm-leads-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Leads
          </b-button>
        </b-card>

        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'crm-utenti-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista Utenti
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div class="text-center my-3" v-if="Caricato == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                    <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Dettagli</span>
                  </template>
                  <b-card bg-variant="Default">
                    <b-row>
                      <b-col md="4" lg="3">
                        <b-form-group label="Ragione Sociale" label-for="company_name">
                            <b-form-input
                              v-model="campiform.billing.company_name"
                              placeholder=""
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Nome *" label-for="name">
                            <validation-provider
                            #default="{ errors }"
                            name="Nome"
                            rules="required"
                            >
                            <b-form-input
                                v-model="campiform.lead.name"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Cognome *" label-for="Surname">
                            <validation-provider
                            #default="{ errors }"
                            name="Cognome"
                            rules="required"
                            >
                            <b-form-input
                                v-model="campiform.lead.surname"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="4" lg="3">
                        <b-form-group label="Email *" label-for="email">
                            <validation-provider
                            #default="{ errors }"
                            name="Email dell'utente"
                            rules="required|email"
                            >
                            <b-form-input
                                v-model="campiform.lead.email"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Telefono" label-for="telefono">
                            <b-form-input
                                v-model="campiform.lead.phone"
                                placeholder=""
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Cellulare" label-for="Cellulare">
                            <b-form-input
                                v-model="campiform.lead.mobile_phone"
                                placeholder=""
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Reseller" label-for="reseller_id">
                            <b-form-select
                                v-model="campiform.lead.reseller_id"
                                :options="options_user_reseller"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Assistente" label-for="assistant_id">
                            <b-form-select
                                v-model="campiform.lead.assistant_id"
                                :options="options_user_assistente"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Valutazione *" label-for="evaluation_id">
                          <validation-provider
                          #default="{ errors }"
                          name="Valutazione"
                          rules="required"
                          >
                          <b-form-select
                              v-model="campiform.lead.evaluation_id"
                              :options="options_evalution"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Rating" label-for="rating">
                            <b-form-select
                                v-model="campiform.lead.rating"
                                :options="options_rating"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Stato *" label-for="status_id">
                            <validation-provider
                            #default="{ errors }"
                            name="Stato"
                            rules="required"
                            >
                            <b-form-select
                                v-model="campiform.lead.status_id"
                                :options="options_status"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Origine *" label-for="source_id">
                            <validation-provider
                            #default="{ errors }"
                            name="Origine"
                            rules="required"
                            >
                            <b-form-select
                                v-model="campiform.lead.source_id"
                                :options="options_source"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Sito web" label-for="website">
                            <b-form-input
                                v-model="campiform.lead.website"
                                placeholder=""
                            />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <label for="textarea-note">Note</label>
                        <b-form-textarea 
                          id="textarea-note"
                          rows="3"
                          v-model="campiform.lead.note"
                        />
                      </b-col>
                    </b-row>

                  </b-card>
                </b-tab>

              
                <b-tab>
                  <template #title>
                    <feather-icon
                      icon="LockIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Fatturazione</span>
                  </template>
                  <b-card bg-variant="Default">
                    <b-row>
                      <b-col md="4" lg="3">
                        <b-form-group label="Nazione" label-for="country_id">
                          <vue-autosuggest
                            ref="autocomplete"
                            v-model="valoreDefaultNazione"
                            :suggestions="filteredOptions"
                            :limit="8"
                            :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'cerca nazione', }"
                            :render-suggestion="renderSuggestion"
                            :get-suggestion-value="getSuggestionValue"
                            @input="onInputChange"
                            @selected="selectHandler"
                          >
                            <template slot-scope="{suggestion}">
                              <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                            </template>
                          </vue-autosuggest>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Indirizzo" label-for="address">
                          <validation-provider
                          #default="{ errors }"
                          name="Indirizzo"
                          rules="min:2"
                          >
                            <b-form-input
                                v-model="campiform.billing.address"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Cap" label-for="zip">
                          <validation-provider
                          #default="{ errors }"
                          name="Cap"
                          rules="min:4"
                          >
                          <b-form-input
                              v-model="campiform.billing.zip"
                              :state="errors.length > 0 ? false:null"
                              placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Città" label-for="city">
                          <validation-provider
                          #default="{ errors }"
                          name="Città"
                          rules="min:2"
                          >
                            <b-form-input
                                v-model="campiform.billing.city"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Provincia" label-for="province_id">
                          <b-form-select
                              v-model="campiform.billing.province_id"
                              :options="options_province"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Partita IVA" label-for="vat_number">
                          <validation-provider
                          #default="{ errors }"
                          name="Partita IVA"
                          rules="regex:^([a-zA-Z0-9]+)$|min:5"
                          >
                            <b-form-input
                              v-model="campiform.billing.vat_number"
                              :state="errors.length > 0 ? false:null"
                              placeholder=""
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Codice fiscale" label-for="fiscal_code">
                          <b-form-input
                            v-model="campiform.billing.fiscal_code"
                            placeholder=""
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Pec" label-for="pec">
                          <b-form-input
                            v-model="campiform.billing.pec"
                            placeholder=""
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Codice SDI" label-for="dest_code">
                          <validation-provider
                          #default="{ errors }"
                          name="Codice SDI"
                          rules="length:7"
                          >
                            <b-form-input
                              v-model="campiform.billing.dest_code"
                              :state="errors.length > 0 ? false:null"
                              placeholder=""
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Regime IVA" label-for="vat_scheme_id">
                          <b-form-select
                            v-model="campiform.billing.vat_scheme_id"
                            :options="options_regine_iva"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-tab>
              </b-tabs>

              <b-row>
                  <b-col md="12" class="text-center">
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="action_press"
                    @click.prevent="validationForm()"
                  >
                    <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                    <div v-else>Salva</div> 
                  </b-button>
                  </b-col>
              </b-row>
              
            </b-form>
          </validation-observer>
        </div>
          
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, BFormInput, BFormTextarea, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, digits, alphaDash, length, min
} from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BSpinner, 
    BCardText, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,

    VueAutosuggest, 

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,     
  },
  data() {
    return {
      id_italina: '114',
      valoreDefaultNazione: "",
      campiform: {
        lead: {
          id: '',
          email: '', 
          name: '', 
          surname: '',
          mobile_phone: '',
          phone: '', 
          reseller_id: '',
          assistant_id: '',
          evaluation_id: '1',
          rating: 3,
          source_id: '1',
          website: '',
          status_id: '1',
          note: ''
        },
        billing: {
          country_id: '',
          province_id: 111,
          address: '',
          zip: '',
          city: '',
          company_name: '',
          vat_number: '',
          fiscal_code: '',
          pec: '',
          dest_code: '',
          vat_scheme_id: null
        }
      },
      options_rating: [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
      ],
      required,
      email,
      alphaDash, 
      digits,
      length,
      min,
      options_user_reseller: [ ],
      options_user_assistente: [ ],
      options_evalution: [ ],
      options_status: [ ],
      options_source: [ ],
      options: [ ],
      options_province: [ ],
      options_regine_iva: [ ],

      Caricato: false,
      errore_caricamento: false,
      submitted: false,
      action_press: false,

      filteredOptions: [],
      limit: 10,     
    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const countryPromise = this.$http.get('api/fs/country/list')
    const provincePromise = this.$http.get('api/fs/province/list')
    const userCrmAssistentiPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee", "role.name":"assistant" }&page=1&perPage=100&status=active')
    const userCrmResellerAssistentiPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee", "role.name":"reseller" }&page=1&perPage=100&status=active')
    const evaluationPromise = this.$http.get('api/fs/lead/evaluation/list')
    const statusPromise = this.$http.get('api/fs/lead/status/list')
    const sourcePromise = this.$http.get('api/fs/lead/source/list')
    const vatSchemePromise = this.$http.get('api/fs/employee/vat-scheme/list')

    Promise.all([countryPromise, provincePromise, userCrmAssistentiPromise, userCrmResellerAssistentiPromise, evaluationPromise, statusPromise, sourcePromise, vatSchemePromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista nazioni
        this.options = response[0].data.reply

        if(response[1].data.statusCode===200){
          //caricamento lista province italiane
          this.options_province = response[1].data.reply
            
          if(response[2].data.statusCode===200){
            //caricamento lista utenti employee assistenti
            let i = 0;
            while (i < response[2].data.reply.meta.total) {
              this.options_user_assistente[i] = { value: response[2].data.reply.data[i].id, text: response[2].data.reply.data[i].name+' '+response[2].data.reply.data[i].surname },
              
              i++;
            }

            if(response[3].data.statusCode===200){
              //caricamento lista utenti employee reseller
              let u = 0;
              while (u < response[3].data.reply.meta.total) {
                this.options_user_reseller[u] = { value: response[3].data.reply.data[u].id, text: response[3].data.reply.data[u].name+' '+response[3].data.reply.data[u].surname },
                
                u++;
              }

              if(response[4].data.statusCode===200){
                //caricamento lista evaluation
                this.options_evalution = response[4].data.reply

                if(response[5].data.statusCode===200){
                  //caricamento lista status
                  this.options_status = response[5].data.reply

                  if(response[6].data.statusCode===200){
                    //caricamento lista source
                    this.options_source = response[6].data.reply

                    if(response[7].data.statusCode===200){
                      //caricamento lista regime iva
                      this.options_regine_iva = response[7].data.reply

                      this.Caricato = true;
                      this.errore_caricamento = false;
                    } else {
                      this.Caricato = false;
                      this.errore_caricamento = true;
                    }
                  } else {
                    this.Caricato = false;
                    this.errore_caricamento = true;
                  }
                } else {
                  this.Caricato = false;
                  this.errore_caricamento = true;
                }
              } else {
                this.Caricato = false;
                this.errore_caricamento = true;
              }
            
            } else {
              this.Caricato = false;
              this.errore_caricamento = true;
            }
          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
            
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })
  },
  methods: {
    onInputChange(testo_ricerca) {
      if (testo_ricerca === '' || testo_ricerca === undefined) {
        return
      }

      //filtra array nazioni
      const filteredData = this.options.filter(item => item.text.toLowerCase().indexOf(testo_ricerca.toLowerCase()) == 0).slice(0, this.limit)
      
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    renderSuggestion(suggestion) {
      //per gestire le voci restituite nella finestra auto-suggest
      return suggestion.item.text;
    },
    getSuggestionValue(suggestion) {
      //per gestire il testo che verra visualizzato dopo la selezione dela voce dalla finestra dell'auto-suggest
      return suggestion.item.text;
    },
    selectHandler(text){
      if (text === '' || text === undefined) {
        return
      } else {
        //associo id_nazione al parametro da passare all'api
        this.campiform.billing.country_id = text.item.value

        if(this.campiform.billing.country_id == this.id_italina){
          //forzo campo "province_id"
          this.campiform.billing.province_id = '111';
          //codice SDI
          this.campiform.billing.dest_code = '';
          //Regime IVA
          this.campiform.billing.vat_scheme_id = '1'; //soggetto
        } else {
          //province_id estera
          this.campiform.billing.province_id = '112';
          //codice SDI
          this.campiform.billing.dest_code = '0000000';
          //Regime IVA
          this.campiform.billing.vat_scheme_id = '2'; //non soggetto
        }
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          console.log('start chiamata api per salvataggio nuovo utente ...')

          //c'è da aggregare altre informazioni da inviare all'api per creare l'utente ???
          console.log(this.campiform)

          this.$http.post('api/crm/lead/card', 
            this.campiform.lead 
            ).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.leadData.id > 0){
                  
                    //procedo al controllo della p.iva
                    if((this.campiform.billing.vat_number != '') && (this.campiform.billing.vat_number !==  null)){                    
                      this.$http.post('api/crm/billing/check_duplicate_vat', {
                        vat_number: this.campiform.billing.vat_number
                      }).then(response_vat_check => {
                        if((response_vat_check.data.statusCode>=200) && (response_vat_check.data.statusCode<=299)){
                          console.log("risposta controllo P.iva ------------------")
                          console.log(response_vat_check)

                          if(response_vat_check.data.reply.data.duplicato == 'si'){
                            //duplicato Lead o CLiente con la stessa P.Iva inserita nel form
                            // => niente salvataggio dati billing per evitare errori di integrità del DB

                            //redirect con messaggio specifico
                            this.$router.replace('/crm/leads')
                            .then(() => {
                              this.$swal({
                                  icon: 'success',
                                  title: 'Nuovo lead creato correttamente',
                                  text: 'I dati di fatturazione sono già presenti nella scheda '+response_vat_check.data.reply.data.ricerca.tipo_scheda+' di '+response_vat_check.data.reply.data.ricerca.company_name,
                                  confirmButtonText: 'chiudi',
                                  customClass: {
                                  confirmButton: 'btn btn-success',
                                  },
                              })
                            })

                          } else {
                            //nessun duplicato => procedo con la normale procedura di salvataggio dei dati di Billings
                            //procediamo al salvataggio del Billing SOLO se ci sono i campi "ragione sociale" compilato
                            if(
                              (this.campiform.billing.company_name != '')
                            ){ 

                              //OK -> procedo al salvataggio dati "billing"
                              this.$http.post('api/crm/billing/lead/card/'+response.data.reply.leadData.id, 
                              this.campiform.billing
                              ).then(response_billing => {
                                if((response_billing.data.statusCode>=200) && (response_billing.data.statusCode<=299)){
                                  //risposta positiva
                                  this.$router.replace('/crm/leads')
                                    .then(() => {
                                      this.$swal({
                                          icon: 'success',
                                          title: 'Nuovo lead con dati fiscali creato correttamente',
                                          confirmButtonText: 'chiudi',
                                          customClass: {
                                          confirmButton: 'btn btn-success',
                                          },
                                      })
                                    })
                                
                                } else {
                                  //risposta negativa (errore sul server)
                                  this.$swal({
                                      icon: 'error',
                                      title: 'Si è verificato un errore in Billing',
                                      text: 'Errore: A01 - '+response_billing.data.reply.message,
                                      customClass: {
                                      confirmButton: 'btn btn-outline-primary',
                                      },
                                  })

                                  this.action_press = false
                                }
                              }).catch(e => {
                                console.log(e);
                              });

                            } else {
                              //non ci sono dati Billing da salvare ... FINE
                              this.$router.replace('/crm/leads')
                                .then(() => {
                                  this.$swal({
                                      icon: 'success',
                                      title: 'Nuovo lead creato correttamente',
                                      confirmButtonText: 'chiudi',
                                      customClass: {
                                      confirmButton: 'btn btn-success',
                                      },
                                  })
                                })
                            }
                          }

                        } else {
                          //risposta negativa (errore sul server)
                          this.$swal({
                              icon: 'error',
                              title: 'Si è verificato un errore durante il controllo della P.Iva',
                              text: response_vat_check.data.reply.message,
                              customClass: {
                              confirmButton: 'btn btn-outline-primary',
                              },
                          })

                        }
                      }).catch(e => {
                        console.log(e);
                      });
                    } else {
                      //niente P.Iva inserita (può essere anche null)
                      //procediamo al salvataggio del Billing SOLO se ci sono i campi "ragione sociale" compilato
                      if(
                        (this.campiform.billing.company_name != '')
                      ){ 

                        //OK -> procedo al salvataggio dati "billing"
                        this.$http.post('api/crm/billing/lead/card/'+response.data.reply.leadData.id, 
                        this.campiform.billing
                        ).then(response_billing => {
                          if((response_billing.data.statusCode>=200) && (response_billing.data.statusCode<=299)){
                            //risposta positiva
                            this.$router.replace('/crm/leads')
                              .then(() => {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Nuovo lead creato correttamente',
                                    confirmButtonText: 'chiudi',
                                    customClass: {
                                    confirmButton: 'btn btn-success',
                                    },
                                })
                              })
                          
                          } else {
                            //risposta negativa (errore sul server)
                            this.$swal({
                                icon: 'error',
                                title: 'Si è verificato un errore in Billing',
                                text: 'Errore: A02 - '+response_billing.data.reply.message,
                                customClass: {
                                confirmButton: 'btn btn-outline-primary',
                                },
                            })

                            this.action_press = false
                          }
                        }).catch(e => {
                          console.log(e);
                        });

                      } else {
                        //non ci sono dati Billing da salvare ... FINE
                        this.$router.replace('/crm/leads')
                          .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: 'Nuovo lead creato correttamente',
                                confirmButtonText: 'chiudi',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                          })
                      }

                    }

                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
                
              } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
              }
            }).catch(e => {
              console.log(e);
            });

        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
